import React from "react"
import PropTypes from "prop-types"

import { SIGN_UP_LINK } from "../../constants/linkConstants"

const getButtonLink = link => {
  if (link === "SINC_SIGN_UP") {
    return SIGN_UP_LINK
  }
  return link
}

const IntegrationButton = ({ buttonData, fullWidth, alternativeColor }) => {
  // this is so we can manage our internal sign up link form one place in the constants. It is provided as the
  // string 'SIGN_UP_LINK' in strapi
  if (buttonData) {
    const link = getButtonLink(buttonData.link)
    return (
      <button
        style={{ marginTop: 20 }}
        className={`btn  ${!alternativeColor && "btn-cta"} large ${
          fullWidth && "full-width"
        }`}
      >
        <a target="_blank" href={link} id={buttonData.elementID}>
          {buttonData.label}
        </a>
      </button>
    )
  }
  return <div />
}

IntegrationButton.propTypes = {
  buttonData: PropTypes.shape({
    link: PropTypes.string.isRequired,
    elementID: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  fullWidth: PropTypes.bool,
  alternativeColor: PropTypes.bool,
}

export default IntegrationButton

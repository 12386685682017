import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row  } from "react-grid-system"
import Markdown from "react-markdown"
import InnerContentContainer from "../layout/innerContentContainer";
import Column from "../layout/column";
import FullWidthSection from "../layout/fullWidthSection";
import IntegrationButton from "./integrationButton";

import { defaultRenderers } from "../../constants/mdxRenderers";

const TopLogoBanner = ({title, image, preSignUpBlurb, sincButton, partnerButton}) => (
    <FullWidthSection>
        <InnerContentContainer >
        <Container>
            <Row>
                <Column sm={12} md={6} lg={8}>
                    <h2>{title}</h2>
                    <Markdown children={preSignUpBlurb} components={defaultRenderers} />
                </Column>
                <Column style={{ textAlign: 'center' }} sm={12} md={6} lg={4}>
                <img
                className="img-responsive"
                src={image}
                style={{ maxHeight: 150, marginLeft: 'auto', marginRight: 'auto' }}
              />
                </Column>
            </Row>
            <Row>
            <Column style={{ display: 'flex', justifyContent: 'center' }} sm={12} md={6} lg={4} offset={{ lg: 2 }}>
                    <IntegrationButton alternativeColor fullWidth buttonData={sincButton} />
                </Column>
                <Column style={{ display: 'flex', justifyContent: 'center' }} sm={12} md={6} offset={{ lg: 2 }} lg={4}>

       <IntegrationButton fullWidth buttonData={partnerButton} />
                </Column>
            </Row>
        </Container>
        </InnerContentContainer>
    </FullWidthSection>
)

TopLogoBanner.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    preSignUpBlurb: PropTypes.string.isRequired,
    partnerButton: PropTypes.shape({
        elementID: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
    sincButton: PropTypes.shape({
        elementID: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
}

export default TopLogoBanner;

import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroBanner from "../components/integrations/heroBanner"
import TopLogoBanner from "../components/integrations/topLogoBanner"
import AboutPartner from "../components/integrations/aboutPartner"
import Hero from "../components/home/hero"

const Integration = ({ data }) => {
  const {
    integration: {
      seo: { titleseo, description },
      screenshot: {
        childImageSharp: { fluid: screenshotFluid },
      },
      logo: { publicURL },
      partnerSignUpButton,
      sincSignUpButton,
      heroBlurb,
      Title,
      preSignUpBlurb,
      aboutPartner,
    },
  } = data
  return (
    <>
      <SEO title={titleseo} description={description} />
      <Layout>
        <TopLogoBanner
          sincButton={sincSignUpButton}
          title={Title}
          image={publicURL}
          partnerButton={partnerSignUpButton}
          preSignUpBlurb={preSignUpBlurb}
        />
        <AboutPartner aboutPartner={aboutPartner} />
        <HeroBanner
          blurb={heroBlurb}
          image={screenshotFluid}
          partnerButton={partnerSignUpButton}
        />
        <Hero />
      </Layout>
    </>
  )
}

export default Integration

export const query = graphql`
  query GetIntegration($slug: String) {
    integration: strapiIntegrations(slug: { eq: $slug }) {
      seo {
        titleseo
        description
      }
      screenshot {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Title
      aboutPartner
      heroBlurb
      preSignUpBlurb
      partnerSignUpButton {
        elementID
        label
        link
      }
      sincSignUpButton {
        elementID
        link
        label
      }
      logo {
        publicURL
      }
    }
  }
`

import React from 'react';
import styled from 'styled-components';

const GradientBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    position: relative;
    opacity: 0.8;
    text-align: center;
    background-image: linear-gradient(to bottom right,#1a4aa6,#0d80b1);
    padding: 6rem 2rem;
`;

export default GradientBackground;
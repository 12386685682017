import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { Container, Row } from "react-grid-system"
import Markdown from "react-markdown"
import AppStoreIcons from "../appStoreIcons"
import InnerContentContainer from "../layout/innerContentContainer"
import GradientBackground from "../layout/gradientBackground"
import Column from "../layout/column"
import IntegrationButton from "./integrationButton"
import { defaultRenderersDarkBackground } from "../../constants/mdxRenderers"

const HeroBanner = ({ image, blurb, partnerButton }) => (
  <GradientBackground>
    <InnerContentContainer>
      <Container>
        <Row>
          <Column
            sm={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Markdown
              className="blog-title-container left"
              children={blurb}
              components={defaultRenderersDarkBackground}
            />
            <AppStoreIcons light />
          </Column>
          <Column sm={12} md={6}>
            <Image
              imgStyle={{
                width: "100%",
                maxHeight: 400,
                objectFit: "contain",
              }}
              fadeIn={false}
              loading={"eager"}
              fluid={image}
            />
            <IntegrationButton fullWidth buttonData={partnerButton} />
          </Column>
        </Row>
      </Container>
    </InnerContentContainer>
  </GradientBackground>
)

HeroBanner.propTypes = {
  image: PropTypes.object.isRequired,
  blurb: PropTypes.string.isRequired,
  partnerButton: PropTypes.shape({
    elementID: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default HeroBanner

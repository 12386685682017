import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from "react-grid-system"
import Markdown from "react-markdown"
import InnerContentContainer from "../layout/innerContentContainer";
import FullWidthSection from "../layout/fullWidthSection";
import Column from "../layout/column";

import { defaultRenderers } from "../../constants/mdxRenderers";

const AboutPartner = ({aboutPartner}) => (
    <FullWidthSection lightGrey>
        <InnerContentContainer>
            <Container>
                <Row>
                    <Column>
                    <Markdown children={aboutPartner} components={defaultRenderers} />
                    </Column>
                </Row>
            </Container>
        </InnerContentContainer>
    </FullWidthSection>
)

AboutPartner.propTypes = {
    aboutPartner: PropTypes.string.isRequired,
}
export default AboutPartner;